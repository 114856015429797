* {
  box-sizing: border-box;
}
.App {
  text-align: center;
}
.clickable {
  cursor: pointer;
  
}
.clickable:hover {

  background-color: #eee;
}

.modal-dialog > * {
  padding: 1rem;
}
 
.modal-header,
.modal-footer {
  background: #efefef;
}
 
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
 
.modal-header .modal-close {
  font-size: 1.5rem;
}
 
.modal p + p {
  margin-top: 1rem;
}
